import React from "react"
import { Box, Typography, AdviqoButton } from "../_common"
import desktopIcon from "./icons/crystal-ball@2x.png"
import classes from "./NotFound.module.scss"
import Image from "next/image"

const NotFound: React.FC = () => {
  return (
    <Box className={classes.wrapper} data-testid="not-found-section">
      <Box className={classes.container}>
        <Typography
          component="h5"
          variant="h5"
          className={classes.title}
          data-testid="not-found-title"
        >
          Ups!
        </Typography>
        <p className={classes.description} data-testid="not-found-text">
          Diesmal wissen wir leider nicht, was du suchst. Versuch’s nochmal!
        </p>
        <Box className={classes.imageWrapper}>
          <Image
            sizes="(min-width: 600px) 200px, 150px"
            fill
            src={desktopIcon}
            alt="Crystal ball"
          />
        </Box>
        <p>
          <AdviqoButton
            to="/"
            buttonClassName={classes.actionButton}
            fullWidth
            label="Zur Startseite"
            testId="go-to-start"
          />
        </p>
      </Box>
    </Box>
  )
}

export default NotFound
