import React from "react"
import { Box, Hidden } from "../../_common"
import RawHTML from "./RawHTML"
import classes from "./Interview.module.scss"

const Interview: React.FC<{ interview: string }> = ({ interview }) => {
  const content = <RawHTML text={interview} rootClass={classes.text} />

  return (
    <>
      <Hidden mdUp>{content}</Hidden>
      <Hidden mdDown>
        <Box className={classes.section}>{content}</Box>
      </Hidden>
    </>
  )
}

export default Interview
