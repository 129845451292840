import React from "react"
import { AdviqoLink } from "../../../_common"
import ChevronIcon from "../../../_common/QuesticoIcon/ChevronIcon"
import classes from "./AstroTVBanner.module.scss"

interface IAstroTVBannerProps {
  shopUrl: string
  imageUrl: string
  clickHandler: React.MouseEventHandler
}

const prepareUrl = (url: string) => {
  const urlObject = new URL(url)
  const { searchParams } = urlObject

  searchParams.set("utm_source", "nf")
  urlObject.search = searchParams.toString()

  return urlObject.toString()
}

const AstroTVBanner: React.FC<IAstroTVBannerProps> = ({
  shopUrl,
  imageUrl,
  clickHandler,
}) => {
  const preparedShopUrl = prepareUrl(shopUrl)

  return (
    <AdviqoLink
      to={preparedShopUrl}
      className={classes.root}
      onClick={clickHandler}
    >
      <img alt="AstroTV-Shop" className={classes.img} src={imageUrl} />
      <div className={classes.details}>
        <div className={classes.text}>
          Entdecke meine Produkte jetzt neu im{" "}
          <span className={classes.astrotv}>AstroTV</span>-Shop.
        </div>
        <div className={classes.link}>
          <div className={classes.linkText}>weitere Infos</div>
          <div className={classes.icon}>
            <ChevronIcon width={9} />
          </div>
        </div>
      </div>
    </AdviqoLink>
  )
}

export default AstroTVBanner
