import React, { useState, useMemo, useEffect } from "react"
import Head from "next/head"

import { InView } from "react-intersection-observer"
import { Box, Typography } from "../_common"
import StaticList from "./StaticList"
import ExpertEntry from "./ExpertEntry"
import useExpertOnlineStatus from "./ExpertsService/hooks/useExpertOnlineStatus"
import { IExpertsIndexesInView, IExpertListingProps } from "./types"
import { updateExpertsIndexesInView } from "./utils"
import { getFilterByTag } from "./Filters/utils"
import {
  ClientTrackingEventName,
  IAdvisorListingFieldState,
  IAdvisorProductType,
} from "../../types"
import { useFetchExperts } from "./ExpertsService"
import useExpertsListingTracking from "../../hooks/tracking/useExpertsListingTracking"
import { usePrevious } from "../../hooks/usePrevious"
import classes from "./Experts.connected.module.scss"

const ExpertsConnected: React.FC<IExpertListingProps> = ({
  size = 3,
  includeListingNos = [],
  categoryNos,
  freeOnly,
  searchTerm = undefined,
  productType = "ALL",
  emptyState,
  withPagination,
  headline = undefined,
  maxPrice = undefined,
  minPrice = undefined,
  gender = undefined,
  tag = undefined,
  sys,
  language,
  listingName,
  orderBy,
  renderExpertItem,
}) => {
  const filter = useMemo(
    () => ({
      size,
      ...(freeOnly && { price_free: IAdvisorListingFieldState.ON }),
      ...(includeListingNos.length > 0 && { ids: includeListingNos }),
      ...(searchTerm && { search: searchTerm }),
      ...(maxPrice &&
        !freeOnly && {
          price: {
            min: minPrice || 0,
            max: maxPrice,
          },
        }),
      ...(productType &&
        productType !== "ALL" &&
        productType !== "ANONYMOUS" && {
          product: productType.toLowerCase() as IAdvisorProductType,
        }),
      // used as product at filters but as separate filters at API https://adviqo.atlassian.net/browse/FEM-679
      ...(productType &&
        productType === "ANONYMOUS" && {
          prs_enabled: "on",
        }),
      ...(categoryNos && categoryNos.length > 0 && { categories: categoryNos }),
      ...(gender && gender !== "ALL" && { gender: gender.toLowerCase() }),
      ...(language && language !== "ALL" && { locales: [language] }),
      ...(tag && getFilterByTag(tag)),
      ...(orderBy && orderBy !== "ALL" && { order_by: orderBy }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(categoryNos),
      freeOnly,
      gender,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(includeListingNos),
      language,
      maxPrice,
      minPrice,
      orderBy,
      productType,
      searchTerm,
      size,
      tag,
    ]
  )

  const [listingContainerInView, setListingContainerInView] = useState(false)
  const [filterTrackingNeeded, setFilterTrackingNeeded] = useState(false)

  /**
   * @note State updates on each scroll action. This triggers
   * render of the whole experts component multiple times on list scroll.
   *
   * @todo Refactor experts InView logic.
   *
   * https://adviqo.atlassian.net/browse/MISSION-2351
   */
  const [expertsIndexesInView, setExpertsIndexesInView] = useState<
    IExpertsIndexesInView
  >({
    min: 0,
    max: size - 1,
  })

  const {
    error,
    loading,
    experts,
    loadMore,
    currentPageNumber,
    isLoadingMore,
  } = useFetchExperts(listingName || "expertsListingWithFilter", filter)

  const { trackEvent: trackExpertsListingEvent } = useExpertsListingTracking()

  const allExpertsIds = useMemo(() => {
    return experts.content.map(({ id }) => id)
  }, [experts])

  const expertOnlineStatuses = useExpertOnlineStatus(
    allExpertsIds,
    listingContainerInView,
    expertsIndexesInView
  )
  const previousFilter = usePrevious(filter)
  /**
   * We don’t want to track filter when state is null or when
   * it derives state very first time (becoming not null).
   *
   * Why?
   * After contentful configuration comes, we don't want track filter state
   * initially, but only after applying filter.
   *
   * Order if effects is important for:
   * - PRODUCT_LIST_VIEW
   * - PRODUCT_LIST_FILTERED
   */

  useEffect(() => {
    if (!loading && !filterTrackingNeeded) {
      trackExpertsListingEvent({
        content: experts.content,
        eventName: ClientTrackingEventName.PRODUCT_LIST_VIEW,
        customClientTrackingProperties: {
          list_id: listingName,
          configuration_id: sys?.id,
        },
        filter,
        search: searchTerm,
        sorting: orderBy,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (
      previousFilter &&
      JSON.stringify(Object.entries(filter)) !==
        JSON.stringify(Object.entries(previousFilter))
    ) {
      setFilterTrackingNeeded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if (filterTrackingNeeded && !loading) {
      trackExpertsListingEvent({
        content: experts.content,
        eventName: ClientTrackingEventName.PRODUCT_LIST_FILTERED,
        customClientTrackingProperties: {
          list_id: listingName,
          configuration_id: sys?.id,
        },
        filter,
        search: searchTerm,
        sorting: orderBy,
      })
      setFilterTrackingNeeded(false)
    }
  }, [filterTrackingNeeded, loading])

  if (error) return <b>Error :(</b>

  return (
    <>
      {withPagination && currentPageNumber > 1 ? (
        <Head>
          <link rel="canonical" href="" key="canonical" />
          <meta name="robots" content="noindex, follow" key="robots" />
        </Head>
      ) : null}
      <Box>
        {!loading && searchTerm && !allExpertsIds.length && (
          <Typography component="p" className={classes.searchTitle}>
            Du hast nach &quot;{searchTerm}&quot; gesucht
          </Typography>
        )}

        {headline && (
          <Typography
            variant="h4"
            component="h2"
            align="center"
            className={classes.headline}
            data-testid="filters-module-headline"
          >
            {headline}
          </Typography>
        )}
        {/* @ts-ignore */}
        <InView
          onChange={(inView) => {
            setListingContainerInView(inView)
          }}
        >
          <StaticList
            content={experts.content}
            isLast={experts.isLast}
            /**
             * We don't need to track anything in StaticList
             * because we track everything here
             */
            getClientTrackingProperties={() => false}
            withPagination={withPagination}
            onLoadMoreClick={loadMore}
            nextPageNumber={currentPageNumber + 1}
            isLoading={loading}
            isLoadingMore={isLoadingMore}
            emptyState={emptyState}
            renderItem={(expert, index) => {
              let { status } = expert
              if (expertOnlineStatuses && expertOnlineStatuses[expert.id]) {
                status = expertOnlineStatuses[expert.id].status
              }

              const updatedExpertData = { ...expert, status }

              return renderExpertItem ? (
                renderExpertItem(updatedExpertData)
              ) : (
                <ExpertEntry
                  onViewChange={(inView) =>
                    setExpertsIndexesInView(
                      updateExpertsIndexesInView(
                        index,
                        inView,
                        expertsIndexesInView
                      )
                    )
                  }
                  expert={updatedExpertData}
                  listingName={listingName}
                />
              )
            }}
          />
        </InView>
      </Box>
    </>
  )
}

export default ExpertsConnected
