import React, { MouseEventHandler, CSSProperties } from "react"

import PauseIcon from "../../../../_common/QuesticoIcon/PauseIcon"
import SpeakerIcon from "../../../../_common/QuesticoIcon/SpeakerIcon"
import ActionButton from "./ActionButton"
import { TPlaybackState } from "./types"

interface IAudioButtonProps {
  hasAudio: boolean
  state: TPlaybackState
  onClick: MouseEventHandler
  rootStyles?: CSSProperties
}

const AudioButton: React.FC<IAudioButtonProps> = ({
  hasAudio,
  state,
  onClick,
}) => {
  const disabled = !hasAudio || state === "error" || state === "loading"

  return (
    <ActionButton
      disabled={disabled}
      onClick={onClick}
      icon={state === "playing" ? <PauseIcon /> : <SpeakerIcon />}
    />
  )
}
export default AudioButton
