export default [
  {
    listingNo: "2123822",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/alexander-kopitkow",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_14/1/small_image/alexander-kopitkow.jpg",
  },
  {
    listingNo: "5900",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/astrid-obersteiner",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_16/1/small_image/astrid-obersteiner.jpg",
  },
  {
    listingNo: "2335282",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/cora-herfurth-keck",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_15/1/small_image/cora-herfurth-keck.jpg",
  },
  {
    listingNo: "309182",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/daniel-kreibich",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_19/1/small_image/daniel-kreibich.jpg",
  },
  {
    listingNo: "2148022",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/matthias-heimberger",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_17/1/small_image/matthias-heimberger.jpg",
  },
  {
    listingNo: "2120612",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/olga-heinert",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_8/1/small_image/olga-heinert.jpg",
  },
  {
    listingNo: "31439",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/rosemarie-vahle",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_37/1/small_image/rosemarie-vahle.jpg",
  },
  {
    listingNo: "322489",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/sabine-lorenz",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_32/1/small_image/sabine-lorenz.jpg",
  },
  {
    listingNo: "2112582",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/sarina-kolibal",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_11/1/small_image/sarina-kolibal.jpg",
  },
  {
    listingNo: "2214962",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/sonja-duerr-burger",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_12/1/small_image/sonja-duerr-burger.jpg",
  },
  {
    listingNo: "2143462",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/sylvia-hart",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_18/1/small_image/sylvia-hart.jpg",
  },
  {
    listingNo: "144046",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/thorsten-gabriel",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_13/1/small_image/thorsten-gabriel.jpg",
  },
  {
    listingNo: "2337682",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/ursula-haimerl",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_20/1/small_image/ursula-haimerl.jpg",
  },
  {
    listingNo: "2193122",
    shopUrl:
      "https://www.astrotv.de/de-DE/produkte/ute-demmer-und-stefan-schmitt",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_36/1/small_image/ute-demmer-und-stefan-schmitt.jpg",
  },
  {
    listingNo: "2125982",
    shopUrl:
      "https://www.astrotv.de/de-DE/produkte/ute-demmer-und-stefan-schmitt",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_36/1/small_image/ute-demmer-und-stefan-schmitt.jpg",
  },
  // fake data
  {
    listingNo: "2335673",
    shopUrl: "https://www.astrotv.de/de-DE/produkte/alexander-kopitkow",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_14/1/small_image/alexander-kopitkow.jpg",
  },
  {
    listingNo: "2002385",
    shopUrl: "https://www.astrotv.de/produkte/andrea-hartmann",
    imageUrl:
      "https://img8.artcom-venture.de/250/category_49/1/small_image/andrea-hartmann.jpg",
  },
]
