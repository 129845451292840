import React from "react"
import { IIconProps } from "./types"

const SwitzerlandFlagIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.125 3.5C1.05406 3.5 0 5.06705 0 7V14C0 15.933 1.05406 17.5 3.125 17.5H16.875C18.9459 17.5 20 15.933 20 14V7C20 5.06705 18.9459 3.5 16.875 3.5"
      fill="#EC1C24"
    />
    <path
      d="M5.9375 12.375H8.4375V14.875H11.25V12.375H13.75V9.5625H11.25V7.0625H8.4375V9.5625H5.9375V12.375Z"
      fill="white"
    />
  </svg>
)

export default SwitzerlandFlagIcon
