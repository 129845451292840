import React from "react"
import { Experts } from ".."
import { Box, Paper, Typography } from "../../_common"
import classes from "./ExpertsNotFound.module.scss"

interface IExpertsNotFoundProps {
  emptyState: React.ReactNode
}

export const ExpertsNotFound: React.FC<IExpertsNotFoundProps> = ({
  emptyState,
}) => {
  return (
    <>
      <Paper
        rootClassName={classes.root}
        bodyClassName={classes.rootBody}
        testId="empty-result"
      >
        {emptyState ?? (
          <>
            <Typography variant="h3" component="h3" className={classes.title}>
              Keine Treffer
            </Typography>
            <Typography component="p" className={classes.text}>
              Zu deiner Suche konnten wir leider nichts finden. Aber so schnell
              geben wir nicht auf: Gib einen anderen Suchbegriff ein oder
              überprüfe die Schreibweise.
            </Typography>
          </>
        )}
      </Paper>
      <Box className={classes.recommendedList} data-testid="recommended-list">
        <Typography
          variant="h4"
          component="h2"
          className={classes.recommendedListTitle}
        >
          Unsere Empfehlungen für dich
        </Typography>
        <Experts
          listingName="Alternative experts listing"
          size={3}
          withPagination={false}
        />
      </Box>
    </>
  )
}
