import React, {
  useEffect,
  useState,
  useCallback,
  ComponentProps,
  useMemo,
  useRef,
} from "react"
import clsx from "clsx"

import {
  IExpertSettings,
  LinkProps,
  IAdvisorProfile,
  ClientTrackingEventName,
} from "../../../types"
import { Grid, Hidden, useTheme, Box, Paper } from "../../_common"
import { useDomRefs } from "../../../hooks/domRefs"
import { RatingOverview } from "../Rating"
import Reviews from "../Reviews"
import ProfileHero from "./ProfileHero"
import Skills from "./Skills"
import Qualifications from "./Qualifications"
import PersonalNote from "./PersonalNote"
import Interview from "./Interview"
import Section from "./ContentSection"
import ProfileHeadline from "./ProfileHeadline"
import AstroTVBannerContainer from "./AstroTVBanner/index"
// import { getPromotedProductInfo } from "../Products/promotedProductUtils"
import RatingPolicy from "./RatingPolicy"
import SideNavigation from "../../NewNavigation/SideNavigation"
import Promotion from "./Promotion"
import ExpertShortStats from "../ExpertShortStats"
import RawHTML from "./RawHTML"
import StickableProducts from "../Products/StickableProducts"
import { ExpertsSuggestions } from "../../ExpertsSuggestions"
import ConsultationSection from "./ConsultationSection"
import { useCheckBrand } from "../../_common/QuesticoIcon/utils/useCheckBrand"
import { themeSpacingToPxConverter } from "../../../themes/utils"
import classes from "./ExpertProfile.module.scss"
import { useTracking } from "../../../hooks/tracking"
import { getExpertTrackingProperties } from "../utils"
import { getRuntimeConfig } from "../../../utils/runtimeConfig"

const { MANDATOR_NUMBER } = getRuntimeConfig()

enum TAnchors {
  top = "Portrait",
  adviceAreas = "Schwerpunkte",
  qualifications = "Qualifikationen",
  reviews = "Bewertungen",
  interview = "Interview",
}

type TAnchorsKey = keyof typeof TAnchors

const anchoreKeys = Object.keys(TAnchors) as Array<TAnchorsKey>

const NAVBAR_HEIGHT = 64

interface ISectionsHeights {
  [anchor: string]: number
}

interface IExpertDescription extends ComponentProps<typeof PersonalNote> {
  description?: string
}

const ExpertDescription: React.FC<IExpertDescription> = ({
  description,
  text,
  textTimestamp,
}) => {
  return (
    <>
      {text && (
        <Section
          title="Aktueller Hinweis"
          titleClassName={classes.sectionTitle}
          rootClassName={classes.personalNote}
          innerClassName={classes.sectionLeftAlign}
          titleVariant="h3"
        >
          <PersonalNote text={text} textTimestamp={textTimestamp} />
        </Section>
      )}

      {description && (
        <Section innerClassName={classes.sectionLeftAlign}>
          <RawHTML text={description} rootClass={classes.description} />
        </Section>
      )}
    </>
  )
}

type IExpertProfileProps = IAdvisorProfile & {
  profileConfig: {
    name: string
    config: IExpertSettings
  }
}

const getSectionHeight = (anchor: string) => {
  const element = document.querySelector(`#${anchor}`) as HTMLElement

  return (element && element.offsetHeight) || 0
}

const onMenuLinkClick = (a: LinkProps, topOffset: number) => {
  const { document, history, scrollTo } = window

  const target = document.getElementById(a.id) as HTMLElement

  if (target) {
    const extraYOffset =
      target.getBoundingClientRect().top + window.pageYOffset - topOffset
    history.pushState(null, "", `#${a.id}`)
    scrollTo({ top: extraYOffset, behavior: "smooth" })
  }
}

const ExpertProfile: React.FC<IExpertProfileProps> = ({
  languages,
  profileConfig,
  advisor,
  categories,
  details,
  reviews,
}) => {
  const {
    rating,
    advisor_code: advisorCode,
    avatar,
    name,
    products,
    listing_number: listingNumber,
    id,
    is_favorite: isFavorite,
    promotion,
    is_prs_enabled: _isPrsEnabled,
    status,
  } = advisor

  const {
    description_teaser: descriptionTeaser,
    description_about: descriptionAbout,
    availability_details_last_change: availabilityDetailsLastChange,
    availability_details: availabilityDetails,
    audio_intro: audioIntro,
    zodiac_sign: zodiacSign,
    qualifications,
    seminars,
    interview,
  } = details

  const { isViversumBrand } = useCheckBrand()

  const { trackEvent } = useTracking()

  const isPrsEnabled = isViversumBrand && _isPrsEnabled

  const sidebarAnchors = useMemo(
    () =>
      anchoreKeys
        .map((id) => ({
          id,
          title: TAnchors[id],
          url: `#${id}`,
        }))
        .filter((anchor) => {
          switch (anchor.id) {
            case "adviceAreas":
              return categories.length > 0
            case "qualifications":
              return qualifications.length > 0 || seminars.length > 0
            case "interview":
              return Boolean(interview)
            default:
              return true
          }
        }),
    [categories.length, interview, qualifications.length, seminars.length]
  )

  const reviewsSectionIsTracked = useRef(false)

  const { refsMap } = useDomRefs()
  const { spacing } = useTheme()
  const navBarHeight = refsMap?.navbar?.current?.offsetHeight || NAVBAR_HEIGHT
  const [
    sectionsHeights,
    setSectionsHeights,
  ] = useState<ISectionsHeights | null>(null)
  const [activeAnchor, setActiveAnchor] = useState<string | null>(null)
  const topOffset = navBarHeight
    ? navBarHeight + themeSpacingToPxConverter(spacing(2))
    : 0

  const getActiveAnchor = useCallback(() => {
    let offset = 0

    const checkAnchor = (anchor: string) => {
      const height = (sectionsHeights && sectionsHeights[anchor]) || 0

      if (height > 0) {
        offset += height

        if (window.scrollY <= offset) {
          return true
        }
      }

      return false
    }

    const result = sidebarAnchors.map((anchor) => anchor.id).find(checkAnchor)

    return result || "top"
  }, [sectionsHeights, sidebarAnchors])

  const getSectionsHeights = useCallback(() => {
    const result = sidebarAnchors.reduce((acc: ISectionsHeights, anchor) => {
      acc[anchor.id] = getSectionHeight(anchor.id)

      return acc
    }, {})

    return result
  }, [sidebarAnchors])

  const scrollHandler = useCallback(() => {
    if (sectionsHeights === null) {
      setSectionsHeights(getSectionsHeights())
    }

    const newAnchor = getActiveAnchor()

    if (newAnchor === "reviews" && !reviewsSectionIsTracked.current) {
      reviewsSectionIsTracked.current = true

      const {
        category,
        url,
        product_id: productId,
      } = getExpertTrackingProperties(listingNumber, name, MANDATOR_NUMBER)

      trackEvent({
        eventName: ClientTrackingEventName.REVIEWS_VIEWED,
        properties: {
          // eslint-disable-next-line camelcase
          product_id: productId,
          // eslint-disable-next-line camelcase
          expert_listing_no: listingNumber,
          category,
          name,
          url,
        },
      })
    }

    if (activeAnchor !== newAnchor) {
      setActiveAnchor(newAnchor)

      if (
        sectionsHeights !== null &&
        (activeAnchor === "top" || newAnchor === "top")
      ) {
        // Recalculating top section height, because it is not always the same
        // Reason: sidebar position can be static or fixed and it affects top section height
        const topSectionHeight = getSectionHeight("top")
        setSectionsHeights({
          ...sectionsHeights,
          top: topSectionHeight,
        })
      }
    }
  }, [activeAnchor, getActiveAnchor, getSectionsHeights, sectionsHeights])

  const resizeHandler = useCallback(() => {
    setSectionsHeights(getSectionsHeights())
  }, [getSectionsHeights])

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    window.addEventListener("resize", resizeHandler)

    return () => {
      window.removeEventListener("scroll", scrollHandler)
      window.removeEventListener("resize", resizeHandler)
    }
  }, [sectionsHeights, scrollHandler, resizeHandler])

  const { config } = profileConfig

  const isOffline = status === "offline"

  return (
    <Box className={classes.root} data-testid="expert-profile">
      <Box className={clsx(classes.column, classes.sidebarColumn)}>
        <ProfileHero
          avatar={avatar}
          name={name}
          status={status}
          id={id}
          listingNumber={listingNumber}
          audioIntro={audioIntro}
          isFavorite={isFavorite}
        />

        <SideNavigation
          menuItem={{ id: "", title: "", url: "", links: sidebarAnchors }}
          isSticky
          onMenuLinkClick={(e, a) => {
            e.preventDefault()
            onMenuLinkClick(a, topOffset)
          }}
          activeMenuUrl={`#${activeAnchor}`}
          testId="sidebar"
        />
      </Box>

      <Box
        className={clsx(classes.column, classes.productsColumn)}
        style={{ "--top-offset": `${topOffset}px` } as React.CSSProperties} // TODO consider to make const value
      >
        <StickableProducts
          showServiceFee
          promotion={promotion}
          listingNo={listingNumber}
          offeredProducts={products}
          photo={avatar}
          displayName={name}
          initSrc="Profile"
          testId="experts-products"
          advisorStatus={status}
          stickableClassName={classes.stickableProducts}
          productsListClassName={classes.stickableProductsList}
          rootClassName={classes.productsWrapper}
        >
          {isOffline && config.expertsSuggestions && (
            <Hidden mdUp implementation="css">
              <ExpertsSuggestions config={config.expertsSuggestions} />
            </Hidden>
          )}
        </StickableProducts>
      </Box>

      {sidebarAnchors.map((anchor) => {
        switch (anchor.id) {
          case "top": {
            return (
              <Paper
                bodyClassName={classes.section}
                anchor={anchor.id}
                key={anchor.id}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.profileInfo}>
                      <Hidden mdUp>
                        <ProfileHero
                          avatar={avatar}
                          name={name}
                          status={status}
                          id={id}
                          listingNumber={listingNumber}
                          audioIntro={audioIntro}
                          isFavorite={isFavorite}
                          {...rating}
                        />
                      </Hidden>

                      <Box>
                        <ProfileHeadline
                          headline={name}
                          subHeadline={advisorCode}
                          onlineStatus={status}
                        />
                        <ExpertShortStats
                          labels={advisor.labels}
                          rating={advisor.rating}
                          readingsCount={advisor.readings_count}
                          languages={languages}
                          sign={zodiacSign}
                        />
                      </Box>
                    </Box>

                    {descriptionTeaser && (
                      <RawHTML
                        text={descriptionTeaser}
                        rootClass={classes.descriptionTeaser}
                      />
                    )}

                    <Box className={classes.expertProductsAndDescWrapper}>
                      <Promotion
                        products={products}
                        promotionData={promotion}
                        rootClassName={classes.promotion}
                      />

                      {isPrsEnabled && <ConsultationSection />}

                      <ExpertDescription
                        text={availabilityDetails}
                        textTimestamp={availabilityDetailsLastChange}
                        description={descriptionAbout}
                      />
                    </Box>

                    <AstroTVBannerContainer listingNo={listingNumber} />
                  </Grid>
                </Grid>
              </Paper>
            )
          }

          case "adviceAreas": {
            return (
              <Paper
                bodyClassName={classes.section}
                anchor={anchor.id}
                key={anchor.id}
              >
                <Section
                  title={TAnchors.adviceAreas}
                  titleClassName={classes.title}
                >
                  <Skills categories={categories} />
                </Section>
              </Paper>
            )
          }

          case "qualifications": {
            return (
              <Paper
                bodyClassName={classes.section}
                anchor={anchor.id}
                key={anchor.id}
              >
                <Qualifications
                  sections={{
                    qualifications,
                    seminars,
                  }}
                />
              </Paper>
            )
          }

          case "reviews": {
            return (
              <Paper
                bodyClassName={classes.section}
                anchor={anchor.id}
                key={anchor.id}
              >
                <Section
                  title={`${reviews.count} ${TAnchors.reviews}`}
                  titleClassName={classes.title}
                  rootClassName={classes.sectionColumn}
                >
                  {config.ratingPolicy && config.ratingPolicy.enabled && (
                    <RatingPolicy ratingPolicy={config.ratingPolicy} />
                  )}
                  <RatingOverview {...reviews} />
                  <Reviews advisorId={id} />
                </Section>
              </Paper>
            )
          }

          case "interview": {
            return (
              <Paper
                bodyClassName={classes.section}
                anchor={anchor.id}
                key={anchor.id}
              >
                <Section
                  title={TAnchors.interview}
                  titleClassName={classes.title}
                >
                  <Interview interview={interview} />
                </Section>
              </Paper>
            )
          }

          default:
            return null
        }
      })}
    </Box>
  )
}

export default ExpertProfile
