import React from "react"
import clsx from "clsx"

import { AdviqoLink, Box, Hidden, Typography, NextImage } from "../../_common"
import { IRatingPolicyJSON } from "../../../types"
import classes from "./RatingPolicy.module.scss"

const RatingPolicy: React.FC<{ ratingPolicy: IRatingPolicyJSON }> = ({
  ratingPolicy,
}) => {
  return (
    <Box className={classes.wrapper} data-testid="rating-policy">
      <Box className={classes.column}>
        {ratingPolicy?.title &&
          ratingPolicy.title.mobile &&
          ratingPolicy.title.desktop && (
            <Typography component="h5" className={classes.title}>
              <Hidden smUp>{ratingPolicy.title.mobile}</Hidden>
              <Hidden mdDown>{ratingPolicy.title.desktop}</Hidden>
            </Typography>
          )}
        {ratingPolicy?.content && (
          <Typography className={classes.body}>
            {ratingPolicy?.content}{" "}
            {ratingPolicy?.link && (
              <AdviqoLink
                to={ratingPolicy?.link.url}
                title={ratingPolicy?.link.title}
                className={classes.link}
              >
                {ratingPolicy?.link.title}
              </AdviqoLink>
            )}
          </Typography>
        )}
      </Box>
      {ratingPolicy?.media && (
        <Box
          className={clsx(classes.column, classes.mediaColumn, classes.media)}
        >
          <NextImage
            src={ratingPolicy?.media.mobile}
            alt={ratingPolicy?.title.mobile}
            width={110}
            height={77}
            data-testid="image-rating"
            className={classes.image}
          />
        </Box>
      )}
    </Box>
  )
}

export default RatingPolicy
