import React from "react"
import { IIconProps } from "./types"

const KeyIcon: React.FC<IIconProps> = ({ height = "100%", width = "100%" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.83891 15.2615C5.54703 15.2615 6.93173 13.8685 6.93173 12.1501C6.93173 10.4317 5.54703 9.0387 3.83891 9.0387C2.1308 9.0387 0.746094 10.4317 0.746094 12.1501C0.746094 13.8685 2.1308 15.2615 3.83891 15.2615Z"
      stroke="#38424F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.02637 9.9544L15.1855 0.738403L17.254 2.81594"
      stroke="#38424F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0928 3.84973L14.1513 5.92727"
      stroke="#38424F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default KeyIcon
