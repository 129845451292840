import React from "react"
import { format } from "date-fns"

import { Box, Typography } from "../../_common"
import { IReview } from "../../../types"
import { StarRating } from "../Rating"
import classes from "./ReviewEntry.module.scss"

const ReviewEntry: React.FC<IReview> = ({
  author,
  rating,
  text,
  created_at,
  title,
}) => {
  return (
    <Box className={classes.root}>
      {title && (
        <Typography className={classes.subject} component="h5" variant="h5">
          <Box>{title}</Box>
        </Typography>
      )}
      {text && (
        <Typography
          component="p"
          color="textSecondary"
          className={classes.comment}
        >
          {text}
        </Typography>
      )}
      <div className={classes.footer}>
        <Typography className={classes.name} component="h6">
          {author}
          <Box className={classes.rating}>
            <StarRating
              rating={{
                average: Number(rating),
                average_rounded: Number(rating),
                count: 0,
              }}
              withDot={false}
            />
          </Box>
        </Typography>
        {Boolean(created_at) && (
          <Box className={classes.meta}>
            <Typography component="div" color="textSecondary">
              Bewertung vom
              <Typography
                component="span"
                className={classes.date}
                color="textPrimary"
              >{` ${format(new Date(created_at), "dd.MM.yyyy")}`}</Typography>
            </Typography>
          </Box>
        )}
      </div>
    </Box>
  )
}

export default ReviewEntry
