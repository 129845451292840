import React from "react"

import { IAdvisorProduct, IPromotion } from "../../../types"
import { Typography, Box } from "../../_common"
import ExpertHeader from "./ExpertPrices"
import { TPromotionData } from "./utils"
import classes from "./Promotions.module.scss"

interface IPromotionProps {
  products: IAdvisorProduct[]
  promotionData: IPromotion | null
  rootClassName?: string
}

// eslint-disable-next-line
const Promo: React.FC<{ promotionData: TPromotionData }> = ({
  promotionData,
}) => {
  if (!promotionData.title && promotionData.subtitle) {
    return (
      <Typography className={classes.promotionHint}>
        {promotionData.subtitle}
      </Typography>
    )
  }

  return (
    <>
      <Box className={classes.promotionHintTitle}>{promotionData.title}</Box>
      <Box className={classes.promotionHintSubtitle}>
        {promotionData.subtitle}
      </Box>
    </>
  )
}

const Promotion: React.FC<IPromotionProps> = ({
  products,
  promotionData,
  rootClassName,
}) => {
  return (
    <Box className={rootClassName}>
      <ExpertHeader
        products={products}
        promotion={promotionData}
        direction="column"
      />

      {/* {promotionData && <Promo promotionData={promotionData} />} */}
    </Box>
  )
}

export default Promotion
