import React from "react"

import { useTranslations } from "next-intl"
import { Typography, Hidden, Grid, Box } from "../../_common"
import { createMarkup } from "../../../utils/utils"
import Section from "./ContentSection"
import { IQualification, ISeminar } from "../../../types"
import classes from "./Qualifications.module.scss"

interface IQualificationsProps {
  sections: {
    qualifications: Array<IQualification>
    seminars: Array<ISeminar>
  }
}

interface IQualificationSectionProps {
  header: string
  section: Array<IQualification | ISeminar>
}

export const QualificationSection: React.FC<IQualificationSectionProps> = ({
  header,
  section,
}) => {
  const subtitle = (
    <Typography variant="h3" className={classes.subtitle}>
      {header}
    </Typography>
  )

  return (
    <>
      <Hidden mdUp>{subtitle}</Hidden>
      <div className={classes.section}>
        <Hidden mdDown>{subtitle}</Hidden>
        <div className={classes.content}>
          <ul className={classes.itemsList}>
            {section
              .slice()
              .sort((a, b) => b.year - a.year)
              .map((q, i) => (
                <li key={i} className={classes.item}>
                  {i < section.length - 1 && (
                    <div className={classes.leftBorder} />
                  )}
                  <Typography
                    component="span"
                    color="textSecondary"
                    className={classes.year}
                  >
                    {q.year}
                  </Typography>
                  <Box>
                    <Typography
                      component="div"
                      color={
                        "description" in q ? "textPrimary" : "textSecondary"
                      }
                      className={classes.desc}
                      dangerouslySetInnerHTML={createMarkup(q.title)}
                    />
                    {"description" in q && (
                      <Typography
                        component="div"
                        color="textSecondary"
                        className={classes.desc}
                        dangerouslySetInnerHTML={createMarkup(q.description)}
                      />
                    )}
                  </Box>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  )
}

const Qualifications: React.FC<IQualificationsProps> = ({ sections }) => {
  const { qualifications, seminars } = sections
  const translate = useTranslations()

  return (
    <Section title="Qualifikationen" titleClassName={classes.sectionTitle}>
      <Grid container spacing={2} justifyContent="center">
        {seminars.length !== 0 && (
          <Grid item xs={12} lg={6}>
            <QualificationSection
              header={`${translate("brandName")} Weiterbildungen`}
              section={seminars}
            />
          </Grid>
        )}
        {qualifications.length !== 0 && (
          <Grid item xs={12} lg={6}>
            <QualificationSection
              header="Weitere Qualifikationen"
              section={qualifications}
            />
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

export default Qualifications
