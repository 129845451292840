import React from "react"
import type { IReview } from "../../../types"
import { Box, AdviqoButton, MainLoader } from "../../_common"
import ReviewEntry from "./ReviewEntry"
import classes from "./Reviews.module.scss"

interface IReviewsProps {
  /**
   * show loader if `true`
   */
  isLoading: boolean
  /**
   * list of reviews
   */
  reviews: IReview[]
  /**
   * if `isLast` is `true` we show "load more" button
   */
  isLast: boolean
  /**
   * passed to "load more" button
   */
  loadMore: () => void
}

const Reviews: React.FC<IReviewsProps> = ({
  isLoading,
  reviews,
  isLast,
  loadMore,
}) => {
  if (isLoading) return <MainLoader fullwidth={false} />

  if (reviews.length === 0) {
    return null
  }

  const filteredReviews = reviews.filter((r) => r.text || r.title)

  return (
    <Box className={classes.root}>
      <Box component="ul" className={classes.list}>
        {filteredReviews.map((r) => (
          <Box
            component="li"
            key={`${r.author}_${r.created_at}`}
            className={classes.entry}
            data-testid={"review-elem"}
          >
            <ReviewEntry {...r} />
          </Box>
        ))}
      </Box>
      {!isLast && (
        <Box className={classes.centerWrapper}>
          <AdviqoButton
            label="Mehr Bewertungen"
            variant="outlined"
            labelClassName={classes.moreReviewsButton}
            size="large"
            onClick={loadMore}
          />
        </Box>
      )}
    </Box>
  )
}

export default Reviews
