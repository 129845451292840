import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import classes from "./PauseIcon.module.scss"

const PauseIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => (
  <IconBase className={classes.root}>
    <svg width="100%" height="100%" fill="none" viewBox="0 0 24 24">
      <path fill={color} d="M7 3h2.5v18H7zM14.5 3H17v18h-2.5z" />
    </svg>
  </IconBase>
)

export default PauseIcon
