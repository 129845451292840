import format from "date-fns/format"
import de from "date-fns/locale/de"

export const getFormattedDate = (
  dateTime: Date | string,
  endFormat = "dd.LL.yyyy | HH:mm"
): string => {
  const dateObj = typeof dateTime === "string" ? new Date(dateTime) : dateTime

  return format(dateObj, endFormat, {
    locale: de,
  })
}
