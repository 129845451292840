import React from "react"
import { IIconProps } from "./types"

const GermanFlagIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.599609 0.5H20.4004V5.2H-0.599609V0.5Z"
      fill="#1A1A1A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.599609 5.19995H20.4004V9.79995H-0.599609V5.19995Z"
      fill="#CA4F40"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.599609 9.80005H20.4004V14.5H-0.599609V9.80005Z"
      fill="#EAB43E"
    />
  </svg>
)

export default GermanFlagIcon
