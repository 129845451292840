import React from "react"
import astrotvData from "./astrotvData"
import AstroTVBanner from "./AstroTVBanner"
import { IListingNumber, ClientTrackingEventName } from "../../../../types"
import { useTracking } from "../../../../hooks/tracking"

interface IAstroTVBannerContainer {
  listingNo: IListingNumber
}

const AstroTVBannerContainer: React.FC<IAstroTVBannerContainer> = ({
  listingNo,
}) => {
  const expert = astrotvData.find((current) => current.listingNo === listingNo)

  const { trackEvent: trackCustomEvent } = useTracking()

  const clickHandler = () => {
    trackCustomEvent({
      eventName: ClientTrackingEventName.BANNER_CLICKED,
      properties: {
        banner_name: "ATV-Shop banner",
        banner_target: expert?.shopUrl,
      },
    })
  }

  if (!expert) {
    return null
  }

  return <AstroTVBanner {...expert} clickHandler={clickHandler} />
}

export default AstroTVBannerContainer
