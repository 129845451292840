"use client"

import React, { useEffect, useState } from "react"
import { getFormattedDate } from "../../../utils/dateTimeUtils"

interface IDateTimeProps {
  value: string | undefined
  format: string
}

const DateTime: React.FC<IDateTimeProps> = ({ value, format }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  if (!visible || !value) return null

  const date = getFormattedDate(value, format)

  return <time>{date}</time>
}

export default DateTime
