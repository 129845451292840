import React from "react"
import clsx from "clsx"
import { TypographyProps } from "@mui/material/Typography"
import { Typography } from "../../_common"
import classes from "./ContentSectionTitle.module.scss"

interface IContentSectionTitleProps {
  titleClassName?: string
  anchor?: string
  variant?: TypographyProps["variant"]
  children: React.ReactNode
}

const ContentSectionTitle: React.FC<IContentSectionTitleProps> = ({
  children,
  titleClassName,
  variant = "h2",
}) => {
  return (
    <Typography
      variant={variant}
      className={clsx(classes.title, titleClassName)}
    >
      {children}
    </Typography>
  )
}

export default ContentSectionTitle
