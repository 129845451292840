import React, { useRef, useState, useEffect, ReactNode } from "react"

import AudioButton from "./AudioButton"
import { TPlaybackState } from "./types"

interface IAudioPlayerProps {
  src: string | undefined
  children?: ReactNode
}

const AudioPlayer: React.FC<IAudioPlayerProps> = ({ src, children }) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [playbackState, setPlaybackState] = useState<TPlaybackState>("ready")
  const hasAudio = Boolean(src)

  const changePlaybackState = (
    nextState: TPlaybackState,
    fnName: keyof Pick<HTMLAudioElement, "load" | "play" | "pause">
  ) => {
    if (!audioRef.current) return

    setPlaybackState(nextState)
    if (audioRef.current[fnName]) {
      audioRef.current[fnName]()
    }
  }

  const onPlay = () => {
    changePlaybackState("playing", "play")
  }
  const onPause = () => {
    changePlaybackState("paused", "pause")
  }

  const selectNextPlaybackState = () => {
    switch (playbackState) {
      case "ready":
      case "paused": {
        onPlay()
        break
      }
      case "playing":
      case "error":
      case "loading":
      default:
        onPause()
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", onPause)
    }

    return () => {
      if (audioRef.current)
        audioRef.current.removeEventListener("ended", onPause)
    }
  }, [audioRef.current])

  const incompatibilityMessage = children || (
    <p>
      Your browser does not support the <code>audio</code> element.
    </p>
  )

  return (
    <>
      <AudioButton
        state={playbackState}
        hasAudio={hasAudio}
        onClick={selectNextPlaybackState}
      />
      <audio
        controls={false}
        src={src}
        style={{ display: "none" }}
        ref={audioRef}
        preload="none"
      >
        <track kind="captions" />
        {incompatibilityMessage}
      </audio>
    </>
  )
}

export default AudioPlayer
