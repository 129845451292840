import React from "react"
import clsx from "clsx"
import { TypographyProps } from "@mui/material/Typography"
import { Box } from "../../_common"
import ContentSectionTitle from "./ContentSectionTitle"
import classes from "./ContentSection.module.scss"

interface IContentSectionProps {
  title?: string
  titleClassName?: string
  rootClassName?: string
  innerClassName?: string
  titleVariant?: TypographyProps["variant"]
  children: React.ReactNode
}

const ContentSection: React.FC<IContentSectionProps> = ({
  title,
  titleClassName,
  rootClassName,
  innerClassName,
  titleVariant = "h2",
  children,
}) => {
  return (
    <Box className={clsx(classes.root, rootClassName)}>
      {title && (
        <ContentSectionTitle
          titleClassName={titleClassName}
          variant={titleVariant}
        >
          {title}
        </ContentSectionTitle>
      )}
      <Box className={clsx(classes.inner, innerClassName)}>{children}</Box>
    </Box>
  )
}

export default ContentSection
