import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"
import classes from "./SpeakerIcon.module.scss"

const SpeakerIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={classes.root}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.23516 8.91235H2.57617V15.0875H5.23516V8.91235Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M12.2579 20.7926L5.23486 15.0875V8.91237L12.2579 3.21191V20.7926Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M15.2583 9.16602C15.9588 9.90334 16.3873 10.9033 16.3873 12.0001C16.3873 13.0969 15.9588 14.0969 15.2583 14.8342"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M16.7051 5.00928C19.0138 6.47932 20.5438 9.05997 20.5438 12.0001C20.5438 14.9402 19.0138 17.5208 16.7051 18.9908"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 14.3V9.59995C3 8.39995 4 7.29995 5.3 7.09995L10.7 6.19995V17.7L5.3 16.8C4 16.6 3 15.6 3 14.3Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.7002 17.4V6.6C10.7002 5.7 11.5002 5 12.4002 5C13.4002 5 14.1002 5.7 14.1002 6.6V17.4C14.1002 18.3 13.3002 19 12.4002 19C11.4002 19 10.7002 18.3 10.7002 17.4Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.3999 8.3999C21.5999 10.3999 21.5999 13.5999 19.3999 15.5999"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.3999 10.2C18.4999 11.2 18.4999 12.8 17.3999 13.8"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default SpeakerIcon
