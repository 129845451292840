import React from "react"

import { ExpansionPanel } from "../../_common"
import AustrianFlagIcon from "../../_common/QuesticoIcon/AustrianFlagIcon"
import GermanFlagIcon from "../../_common/QuesticoIcon/GermanFlagIcon"
import KeyIcon from "../../_common/QuesticoIcon/KeyIcon"
import SwitzerlandFlagIcon from "../../_common/QuesticoIcon/SwitzerlandFlagIcon"
import classes from "./ConsultationSection.module.scss"

interface IPhonePriceSection {
  iconNode: React.ReactNode
  phone: string
  price: string
  info: React.ReactNode
}

const phonePriceData = [
  {
    iconNode: <GermanFlagIcon width={20} />,
    phone: "0900-5909606",
    price: "1,99 €/Min.",
    info: <>a. d. deutschen Festnetz - Mobilfunk abweichend.*</>,
  },
  {
    iconNode: <AustrianFlagIcon width={20} />,
    phone: "0900-5111520",
    price: "2,17 €/Min.",
    info: <>a. d. österreichischen Fest- und Mobilfunknetz.*</>,
  },
  {
    iconNode: <SwitzerlandFlagIcon width={20} />,
    phone: "0901-456710",
    price: "3.00 Fr./Min.",
    info: <>a. d. schweizer Fest- und Mobilfunknetz.*</>,
  },
]

const PhonePriceSection: React.FC<IPhonePriceSection> = ({
  iconNode,
  phone,
  price,
  info,
}) => {
  return (
    <>
      <div className={classes.numberHeadline}>
        {iconNode}
        {phone}
      </div>
      <div className={classes.info}>
        <span className={classes.bold}>{price}</span> {info}
      </div>
    </>
  )
}

const PhonePriceList = () => {
  return (
    <>
      {phonePriceData.map((item, key) => (
        <PhonePriceSection {...item} key={key} />
      ))}
    </>
  )
}

const ConsultationDetails: React.FC = () => {
  return (
    <>
      <PhonePriceList />
      <div className={classes.info}>
        Wählen Sie bitte die Rufnummer. Im Rahmen des Auswahlmenüs drücken Sie
        bitte die 1 und geben den Beratercode ein. Sie werden dann zu dem
        ausgewählten Berater verbunden.
      </div>
      <div>
        *Alle Preise sind Endpreise pro Minute inklusive gesetzlicher
        Umsatzsteuer.
      </div>
    </>
  )
}

const consultationData = {
  title: (
    <>
      <KeyIcon width={16} /> 0900-Beratung
    </>
  ),
  details: <ConsultationDetails />,
}

const ConsultationSection: React.FC = () => {
  return (
    <ExpansionPanel
      items={[consultationData]}
      headingClassName={classes.mainHeadline}
      detailsClassName={classes.details}
      panelClassName={classes.panel}
    />
  )
}

export default ConsultationSection
