import React from "react"
import { Typography, Box } from "../../_common"
import { IAdvisorStatus } from "../../../types"
import ExpertOnlineStatusLabel from "../ExpertOnlineStatusLabel"
import classes from "./ProfileHeadline.module.scss"

interface IProfileHeadlineProps {
  headline: string
  subHeadline?: string
  onlineStatus?: IAdvisorStatus
}

const ProfileHeadline: React.FC<IProfileHeadlineProps> = ({
  headline,
  subHeadline,
  onlineStatus,
}) => {
  const hasSubheadline = Boolean(subHeadline)

  return (
    <Box className={classes.wrapper}>
      <div className={classes.caption}>
        <Typography
          component="h1"
          variant="h3"
          className={classes.name}
          gutterBottom={hasSubheadline}
          data-testid="displayName"
          color="textPrimary"
        >
          {headline}
        </Typography>
        {onlineStatus && (
          <ExpertOnlineStatusLabel onlineStatus={onlineStatus} />
        )}
      </div>
      {hasSubheadline && (
        <Typography component="h3" variant="subtitle2" color="textSecondary">
          {`Beratercode ${subHeadline}`}
        </Typography>
      )}
    </Box>
  )
}

export default ProfileHeadline
