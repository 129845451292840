import React from "react"

import { Box } from "../../../../_common"
import AudioPlayer from "./AudioPlayer"
import FavouriteButton from "./FavouriteButton"
import classes from "./Actions.module.scss"
interface IActionsProps {
  id: number
  listingNumber: string
  audioIntro: string | null
  isFavorite: boolean
}

const Actions: React.FC<IActionsProps> = ({
  listingNumber,
  id,
  audioIntro,
  isFavorite,
}) => {
  return (
    <Box className={classes.actions}>
      <AudioPlayer src={audioIntro || undefined} />
      <FavouriteButton
        listingNumber={listingNumber}
        id={id}
        isFavorite={isFavorite}
      />
    </Box>
  )
}

export default Actions
