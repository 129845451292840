import React from "react"
import { IIconProps } from "./types"

const AustrianFlagIcon: React.FC<IIconProps> = ({
  height = "100%",
  width = "100%",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_19868_4533)">
      <path d="M0 7.72217H20V13.2777H0V7.72217Z" fill="#EEEEEE" />
      <path
        d="M17.7778 3.5H2.22222C1.63285 3.5 1.06762 3.72692 0.650874 4.13085C0.234126 4.53477 0 5.08261 0 5.65385L0 7.80769H20V5.65385C20 5.08261 19.7659 4.53477 19.3491 4.13085C18.9324 3.72692 18.3671 3.5 17.7778 3.5ZM2.22222 17.5H17.7778C18.3671 17.5 18.9324 17.2731 19.3491 16.8692C19.7659 16.4652 20 15.9174 20 15.3462V13.1923H0V15.3462C0 15.9174 0.234126 16.4652 0.650874 16.8692C1.06762 17.2731 1.63285 17.5 2.22222 17.5Z"
        fill="#ED2939"
      />
    </g>
    <defs>
      <clipPath id="clip0_19868_4533">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default AustrianFlagIcon
