import React from "react"
import clsx from "clsx"

import { IAdvisor } from "../../../../types"
import { Box } from "../../../_common"
import Avatar from "../../Avatar"
import Actions from "./Actions"
import classes from "./ProfileHero.module.scss"

type TProfileHeroProps = Pick<IAdvisor, "avatar" | "name" | "status" | "id"> & {
  rootClassName?: string
  audioIntro: string | null
  listingNumber: IAdvisor["listing_number"]
  isFavorite: IAdvisor["is_favorite"]
}

const ProfileHero: React.FC<TProfileHeroProps> = ({
  name,
  avatar,
  rootClassName,
  status,
  id,
  listingNumber,
  audioIntro,
  isFavorite,
}) => {
  const wrapperClassName = clsx(classes.wrapper, rootClassName)

  return (
    <Box className={wrapperClassName}>
      <Box className={classes.avatarWrapper}>
        <Avatar photo={avatar} displayName={name} onlineStatus={status} />
        <Actions
          audioIntro={audioIntro}
          id={id}
          listingNumber={listingNumber}
          isFavorite={isFavorite}
        />
      </Box>
    </Box>
  )
}

export default ProfileHero
