import React from "react"
import clsx from "clsx"
import { IAdvisorProfile } from "../../../types"
import { Box, Typography, Grid } from "../../_common"
import classes from "./Skills.module.scss"

type ISkillsProps = {
  categories: IAdvisorProfile["categories"]
}

interface ISkillsBlockProps {
  title: string
  skills: IAdvisorProfile["categories"]
}

const SkillsBlock: React.FC<ISkillsBlockProps> = ({ skills, title }) => {
  return (
    <Box className={classes.skillsBlock}>
      <Typography variant="h3" align="center" className={classes.blockTitle}>
        {title}
      </Typography>

      <ul className={classes.skillsList}>
        {skills.map((skill) => (
          <li key={skill.id} className={clsx(classes.skillsListItem)}>
            <img src={skill.icon} alt={skill.name} />
            {skill.name}
          </li>
        ))}
      </ul>
    </Box>
  )
}

const Skills: React.FC<ISkillsProps> = ({ categories }) => {
  const methods = categories.filter((c) => c.type === "method")
  const topics = categories.filter((c) => c.type === "topic")
  return (
    <Grid container spacing={2} justifyContent="center">
      {methods && methods.length !== 0 && (
        <Grid item xs={12} lg={6}>
          <SkillsBlock title="Beratungsmethoden" skills={methods} />
        </Grid>
      )}
      {topics && topics.length !== 0 && (
        <Grid item xs={12} lg={6}>
          <SkillsBlock title="Lebensbereiche" skills={topics} />
        </Grid>
      )}
    </Grid>
  )
}

export default Skills
