import React from "react"
import RawHTML from "./RawHTML"
import classes from "./PersonalNote.module.scss"
import DateTime from "../../_common/DateTime/DateTime.client"

type IPersonalNoteProps = {
  text: string
  textTimestamp: string | null
}

const PersonalNote: React.FC<IPersonalNoteProps> = ({
  text,
  textTimestamp,
}) => {
  return (
    <>
      <RawHTML text={text} rootClass={classes.text} />

      {textTimestamp && (
        <span className={classes.date}>
          veröffentlicht: <DateTime value={textTimestamp} format="PPPp" /> Uhr
        </span>
      )}
    </>
  )
}

export default PersonalNote
