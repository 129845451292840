"use client"

import React, { ComponentProps, useEffect } from "react"

import { useTracking } from "../../hooks/tracking"
import {
  IListingNumber,
  CustomAttributesEnum,
  ICustomAttributesArray,
  ClientTrackingEventName,
} from "../../types"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { useAuthentication } from "../../hooks/authentication"
import ExpertProfile from "./ExpertProfile"
import { getProductTrackingData } from "./utils"
import { updateRecentExpertsData } from "../MyExperts/utils"
import NotFound from "../404"
import { Paper } from "../_common"
import useFetchExpertProfileAPI from "./ExpertsService/hooks/useFetchExpertProfileAPI"
import useMyFavorites from "../../hooks/myFavorites/useMyFavorites"

interface IExpertProfileProps {
  listingNo: IListingNumber
  profileConfig: ComponentProps<typeof ExpertProfile>["profileConfig"]
}

const ExpertProfileConnected: React.FC<IExpertProfileProps> = ({
  listingNo,
  profileConfig,
}) => {
  const trackingRef = React.useRef({
    [ClientTrackingEventName.PRODUCT_VIEWED]: false,
  })

  const { getRuntimeConfig } = useRuntimeConfig()

  const { MANDATOR_NUMBER } = getRuntimeConfig()

  const { userId } = useAuthentication()

  const { trackEvent, getProperty } = useTracking()

  const { expert, error, loading, purgeCache } = useFetchExpertProfileAPI(
    { listingNo },
    true
  )

  useEffect(() => {
    return () => {
      purgeCache()
    }
  }, [purgeCache])

  const { checkIsFavourite } = useMyFavorites()
  const { id } = expert?.advisor || {}

  const favourited = id ? checkIsFavourite(id) : false

  useEffect(() => {
    if (!userId || !expert) {
      return
    }

    updateRecentExpertsData(userId, expert.advisor.id)
  }, [userId, expert])

  useEffect(() => {
    if (
      !loading &&
      expert?.advisor &&
      trackingRef.current[ClientTrackingEventName.PRODUCT_VIEWED] === false
    ) {
      trackEvent({
        eventName: ClientTrackingEventName.PRODUCT_VIEWED,
        properties: {
          ...getProductTrackingData(
            expert.advisor,
            MANDATOR_NUMBER,
            getProperty("listingName")
          ),
          favourited,
        },
      })
      trackingRef.current[ClientTrackingEventName.PRODUCT_VIEWED] = true
    }
  }, [expert?.advisor, loading, trackEvent, MANDATOR_NUMBER, getProperty, favourited])

  if (error) return <NotFound />

  if (!expert) return <Paper loading />

  const customAttributes: ICustomAttributesArray = []

  if (expert.advisor.search_engine_denied) {
    customAttributes.push({
      id: CustomAttributesEnum.NO_INDEX,
      value: "true",
    })
  }

  return <ExpertProfile {...expert} profileConfig={profileConfig} />
}

export default ExpertProfileConnected
